import React from 'react';
import { Container, TextContainer } from './styled';
import { Picture } from '../../../componentUI/complex/Picture';
import { CertPreview } from './assets';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../componentUI/simple/Title';
import { Text } from '../../../componentUI/simple/Text';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { useIsMobile } from '../../../hooks';

export const InfoBlock = () => {
  const isMobile = useIsMobile();

  return (
    <Container>
      <Picture url={CertPreview} width={305} height={432} />
      <TextContainer>
        <Title
          size={TITLE_SIZE.h4}
          colorText={COLOR_MAP.text.brandColor}
          weight={TITLE_WEIGHT.normal}
          style={{ maxWidth: isMobile ? undefined : '430px', textAlign: isMobile ? 'center' : undefined }}
        >
          The code can be found in the authenticity certificate, right below the order number
        </Title>
        <div>
          <Title
            size={TITLE_SIZE.h5}
            weight={TITLE_WEIGHT.normal}
            margins={[0, 0, 12, 0]}
            style={{ textAlign: isMobile ? 'center' : undefined }}
          >
            Did Not Find Your Certificate?
          </Title>
          <Text colorText={COLOR_MAP.text.darkGrey} style={{ textAlign: isMobile ? 'center' : undefined }}>
            In case you were not able to find your authenticity certificate, feel free to contact us via our live chat,
            our email, or our Instagram.
          </Text>
        </div>
      </TextContainer>
    </Container>
  );
};

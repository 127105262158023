import React from 'react';
import {
  ConditionalBlock,
  Container,
  CopyrightInfo,
  Info,
  Line,
  LinkBlock,
  Links,
  SocialMedia,
  Wrapper,
} from './styled';
import { Text } from '../../../componentUI/simple/Text';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Facebook, Twitter, Instagram, Youtube, Reddit } from './svg';
import { LinkClassic } from '../../../componentUI/simple/Link';
import { sendLinkEvent } from '../../../analytics/analytics';

export const Footer = () => {
  const trackLinkClick = (action: string) => {
    sendLinkEvent(action);
  };

  const currentYear = new Date().getFullYear();

  return (
    <Wrapper>
      <Container>
        <Links>
          <LinkBlock>
            <LinkClassic
              onClick={() => trackLinkClick('Home')}
              href="https://legitgrails.com"
              target="_blank"
              colorText={COLOR_MAP.text.grey}
            >
              Home
            </LinkClassic>
            <LinkClassic
              onClick={() => trackLinkClick('OurServices')}
              href="https://legitgrails.com/pages/services"
              target="_blank"
              colorText={COLOR_MAP.text.grey}
            >
              Our services
            </LinkClassic>
            <LinkClassic
              onClick={() => trackLinkClick('BusinessSolutions')}
              href="https://legitgrails.com/pages/business-solutions"
              target="_blank"
              colorText={COLOR_MAP.text.grey}
            >
              Business solutions
            </LinkClassic>
          </LinkBlock>
          <LinkBlock>
            <LinkClassic
              onClick={() => trackLinkClick('VerifyCertificate')}
              href="https://certificates.legitgrails.com"
              target="_blank"
              colorText={COLOR_MAP.text.grey}
            >
              Verify Certificate
            </LinkClassic>
            <LinkClassic
              onClick={() => trackLinkClick('Courses')}
              href="https://legitgrails.getlearnworlds.com"
              target="_blank"
              colorText={COLOR_MAP.text.grey}
            >
              Courses
            </LinkClassic>
            <LinkClassic
              onClick={() => trackLinkClick('Guides')}
              href="https://legitgrails.com/pages/guides"
              target="_blank"
              colorText={COLOR_MAP.text.grey}
            >
              Guides
            </LinkClassic>
          </LinkBlock>
          <LinkBlock>
            {/* <LinkClassic href="" target="_blank" colorText={COLOR_MAP.text.grey}> */}
            {/*  Reseller insights */}
            {/* </LinkClassic> */}
            {/* <LinkClassic href="" target="_blank" colorText={COLOR_MAP.text.grey}> */}
            {/*  Case studies */}
            {/* </LinkClassic> */}
            <LinkClassic
              onClick={() => trackLinkClick('About')}
              href="https://legitgrails.com/pages/why-legitgrails"
              target="_blank"
              colorText={COLOR_MAP.text.grey}
            >
              About LegitGrails
            </LinkClassic>
          </LinkBlock>
        </Links>
        <Line />
        <Info>
          <SocialMedia>
            <LinkClassic
              onClick={() => trackLinkClick('Facebook')}
              href="https://www.facebook.com/legitgrails/"
              target="_blank"
            >
              <Facebook />
            </LinkClassic>
            <LinkClassic
              onClick={() => trackLinkClick('Twitter')}
              href="https://twitter.com/LegitGrails"
              target="_blank"
            >
              <Twitter />
            </LinkClassic>
            <LinkClassic
              onClick={() => trackLinkClick('              <Instagram />\n')}
              href="https://www.instagram.com/legitgrails/"
              target="_blank"
            >
              <Instagram />
            </LinkClassic>
            {/* <LinkClassic href="" target="_blank"> */}
            {/*  <Telegram /> */}
            {/* </LinkClassic> */}
            <LinkClassic
              onClick={() => trackLinkClick('Youtube')}
              href="https://www.youtube.com/@legitgrails/featured"
              target="_blank"
            >
              <Youtube />
            </LinkClassic>
            <LinkClassic
              onClick={() => trackLinkClick('Reddit')}
              href="https://www.reddit.com/r/LegitGrailsHub/"
              target="_blank"
            >
              <Reddit />
            </LinkClassic>
          </SocialMedia>
          <ConditionalBlock>
            <div style={{ display: 'flex', gap: '20px', marginBottom: '12px' }}>
              <LinkClassic
                onClick={() => trackLinkClick('PrivacyPolicy')}
                href="https://legitgrails.com/policies/privacy-policy"
                target="_blank"
                colorText={COLOR_MAP.text.brandColor}
                style={{ textDecoration: 'underline' }}
              >
                Privacy policy
              </LinkClassic>
              <LinkClassic
                onClick={() => trackLinkClick('Terms')}
                href="https://legitgrails.com/policies/terms-of-service"
                target="_blank"
                colorText={COLOR_MAP.text.brandColor}
                style={{ textDecoration: 'underline' }}
              >
                Terms of Use
              </LinkClassic>
            </div>
            <CopyrightInfo>
              <Text colorText={COLOR_MAP.text.darkGrey} margins={[0, 0, 12, 0]} style={{ textAlign: 'center' }}>
                Copyright © {currentYear} LegitGrails. All rights reserved.
              </Text>
              <Text colorText={COLOR_MAP.text.darkGrey} style={{ textAlign: 'center' }}>
                Ahtri tn 12, 10151, Tallinn, Estonia
              </Text>
            </CopyrightInfo>
          </ConditionalBlock>
        </Info>
      </Container>
    </Wrapper>
  );
};

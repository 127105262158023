import styled from 'styled-components';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Input } from '../../../componentUI/simple/Input';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { Title } from '../../../componentUI/simple/Title';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${COLOR_MAP.background.darkGrey};
  //padding-bottom: 100px;

  padding: 60px 16px;

  ${truthfulAdaptive.minWidth.tablet} {
    padding: 90px 40px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 100px 40px;
  }
`;

export const SecondTitleStyled = styled(Title)`
  text-align: center;
  margin-bottom: 20px;
  max-width: 679px;

  ${truthfulAdaptive.minWidth.desktop1440} {
    margin-bottom: 80px;
    margin-top: 8px;
  }
`;

export const ControlContainer = styled.form`
  display: flex;
  gap: 20px;

  flex-direction: column;
  width: 100%;

  ${truthfulAdaptive.minWidth.tablet} {
    flex-direction: row;
    max-width: 686px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    max-width: 850px;
  }
`;

export const StyledInput = styled(Input)`
  flex-grow: 1;
`;

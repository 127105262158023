import { useContext } from 'react';
import { CertContext } from '../constants';

export const useGetCertContext = () => {
  const context = useContext(CertContext);

  if (!context) {
    throw new Error('RepaymentUnitsContext must be used within a RepaymentUnitsContextProvider');
  }

  return context;
};

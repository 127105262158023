import React, { useState } from 'react';
// eslint-disable-next-line import/no-named-default
import styled from 'styled-components';
// eslint-disable-next-line import/no-named-default
import { SecondButton } from '../../../componentUI/simple/Button';
import { DownloadIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { useIsMobile } from '../../../hooks';
import { useGetCertContext } from '../../hooks/useGetCertContext';

import './style.css';
import { isResponseCert } from '../../types';
import { Skeleton } from '../../../componentUI/complex/Skeleton';
import { sendLinkEvent } from '../../../analytics/analytics';

const Container = styled.div<{ isOld: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isOld }) => (isOld ? 'align-items: center;' : 'align-items: flex-end;')}

  width: 100%;

  ${truthfulAdaptive.minWidth.tablet} {
    width: auto;

    flex-direction: column;
    ${({ isOld }) => (isOld ? 'align-items: center;' : 'align-items: flex-start;')}
  }
`;

export const SkeletonPreviewContainer = styled.div<{ isOld: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  ${truthfulAdaptive.minWidth.tablet} {
    width: auto;

    flex-direction: column;
    ${({ isOld }) => (isOld ? 'align-items: center;' : 'align-items: flex-start;')}
  }
`;

const HeightImg = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 343px;

  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06), 0 3px 5px 0 rgba(150, 112, 38, 0.04);

  overflow: hidden;

  ${truthfulAdaptive.minWidth.tablet} {
    max-width: 558px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    max-width: 722px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    max-width: 884px;
  }
`;

export const SkeletonHeightImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 343px;
  max-height: 514px;
  margin-bottom: 32px;

  overflow: hidden;

  ${truthfulAdaptive.minWidth.tablet} {
    max-width: 558px;
    max-height: initial;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    max-width: 722px;
    max-height: initial;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    max-width: 884px;
    max-height: initial;
  }
`;

const StyledImage = styled.img<{ isLoaded: boolean }>`
  position: relative;
  width: 100%;
  height: auto;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const SkeletonLoader = styled.div<{ isLoaded: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${({ isLoaded }) => (isLoaded ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
`;

export const PreviewCertImg = () => {
  const isMobile = useIsMobile();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { responseCert } = useGetCertContext();

  if (!responseCert?.certificateUrl) return null;

  // GA
  const onLinkClick = () => {
    sendLinkEvent('DownloadCertificate');
  };

  return (
    <Container isOld={!isResponseCert(responseCert)}>
      <div style={{ width: '100%', marginBottom: '32px', display: 'flex', justifyContent: 'center' }}>
        <HeightImg>
          <SkeletonLoader isLoaded={isImageLoaded}>
            <Skeleton variant="rectangular" height="100%" width="100%" />
          </SkeletonLoader>
          <StyledImage
            src={responseCert.certificateUrl}
            alt="certificate"
            onLoad={() => setIsImageLoaded(true)}
            isLoaded={isImageLoaded}
          />
        </HeightImg>
      </div>

      <SecondButton
        // @ts-ignore
        as="a"
        href={responseCert.certificateUrl}
        target="_blank"
        isStretch={isMobile}
        onClick={() => onLinkClick}
      >
        <DownloadIcon />
        DOWNLOAD CERTIFICATE
      </SecondButton>
    </Container>
  );
};

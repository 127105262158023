import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { breakpoints as breakpointsMap } from '../../componentUI';

const breakpoints = [
  `${breakpointsMap.mobile}px`,
  `${breakpointsMap.tablet}px`,
  `${breakpointsMap.desktop}px`,
] as const;

export const AppTheme = ({ children }: { children: JSX.Element }) => {
  const styledTheme = useMemo(
    () => ({
      breakpoints,
    }),
    [],
  );

  return <ThemeProvider theme={styledTheme}>{children}</ThemeProvider>;
};

// Расширяем интерфейс, которые используют styled-components для темизации
declare module 'styled-components' {
  export interface DefaultTheme {
    breakpoints: typeof breakpoints;
  }
}

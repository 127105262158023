/* eslint-disable max-len */
import React from 'react';
import { COLOR_MAP } from '../../../componentUI/colorsMap';

const SurveyDoneIcon = ({ size = '77' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_18476_26836)">
        <path
          d="M77 38.5C77 59.763 59.763 77 38.5 77C17.237 77 0 59.763 0 38.5C0 17.237 17.237 0 38.5 0C59.763 0 77 17.237 77 38.5Z"
          fill="#F6DFB4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.8008 24.4581C57.0059 25.4215 57.1812 27.1543 56.1925 28.3284L36.1223 52.1617C35.6301 52.7462 34.9126 53.1075 34.1384 53.1605C33.3643 53.2136 32.602 52.9537 32.0306 52.4421L21.9955 43.4558C20.8479 42.428 20.7726 40.6884 21.8273 39.5701C22.8821 38.4519 24.6676 38.3785 25.8152 39.4063L33.6522 46.4242L51.8287 24.8397C52.8174 23.6656 54.5958 23.4947 55.8008 24.4581Z"
        fill="#614E2C"
      />
      <defs>
        <filter
          id="filter0_b_18476_26836"
          x="-3.73658"
          y="-3.73658"
          width="84.4732"
          height="84.4732"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.86829" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18476_26836" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_18476_26836" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const HelpCircleIcon = ({ size = '24', color = '#CBAD73' }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09 9.49999C9.3251 8.83166 9.78915 8.2681 10.4 7.90912C11.0108 7.55015 11.7289 7.41893 12.4272 7.5387C13.1255 7.65848 13.7588 8.02151 14.2151 8.56352C14.6713 9.10552 14.9211 9.79151 14.92 10.5C14.92 12.5 11.92 13.5 11.92 13.5"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 17.5H12.01" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const TriangleError = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.21335 12L6.86001 2.57335C6.97886 2.37742 7.1462 2.21543 7.34588 2.103C7.54557 1.99058 7.77086 1.93152 8.00001 1.93152C8.22917 1.93152 8.45446 1.99058 8.65414 2.103C8.85383 2.21543 9.02117 2.37742 9.14001 2.57335L14.7867 12C14.9031 12.2016 14.9647 12.4302 14.9654 12.663C14.966 12.8958 14.9057 13.1248 14.7904 13.327C14.6751 13.5293 14.5089 13.6978 14.3082 13.8159C14.1076 13.934 13.8795 13.9975 13.6467 14H2.35335C2.12055 13.9975 1.89247 13.934 1.69182 13.8159C1.49116 13.6978 1.32492 13.5293 1.20963 13.327C1.09434 13.1248 1.03402 12.8958 1.03467 12.663C1.03533 12.4302 1.09693 12.2016 1.21335 12Z"
        stroke="#DE4141"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 6V8.66667" stroke="#DE4141" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11.3333H8.00667" stroke="#DE4141" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const CloseIconNew = ({ size = '24', color = COLOR_MAP.controls.fillNormal }: { size?: string; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6L6 18" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 6L18 18" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export { SurveyDoneIcon, HelpCircleIcon, TriangleError, CloseIconNew };

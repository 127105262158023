import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import './scss/_vars.scss';

import { Route, Routes } from 'react-router-dom';
import { Certificate } from './Certificate';
import { CertContext } from './Certificate/constants';
import { ResponseType } from './Certificate/types';
import { ErrorHandler } from './ErrorHandler';
import { UniversalOrderPage } from './Certificate/container/UniversalOrderPage';
import * as analytics from './analytics/analytics';
import { useGoogleAnalytics } from './analytics';

export const App = () => {
  const [responseCert, setResponseStart] = useState<ResponseType | null>(null);

  const context = useMemo(() => ({ responseCert, setResponseStart }), [responseCert]);

  useEffect(() => {
    analytics.init();
  }, []);

  useGoogleAnalytics();

  return (
    <div className="App" style={{ minHeight: '100vh' }}>
      <CertContext.Provider value={context}>
        <Routes>
          <Route element={<Certificate />} path="/" />
          <Route element={<UniversalOrderPage />} path="/:id" />
          <Route element={<ErrorHandler />} path="/error" />
        </Routes>
      </CertContext.Provider>
    </div>
  );
};

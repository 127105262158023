import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { Z_INDEX_LAYERS } from '../../constants';

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const PreloaderScreen = styled.div<{ hide: boolean }>`
  bottom: 0;
  display: block;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${Z_INDEX_LAYERS.overlayLoaderLayer};
  transition: opacity 0.8s 2.4s;
  background-color: ${COLOR_MAP.loader.background};
  animation: ${({ hide }) =>
    hide
      ? css`
          ${fadeOut} 1s forwards
        `
      : 'none'};
`;

const PreloaderWrapper = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  height: auto;
`;

const Path = styled.path<{ dashOffset: boolean }>`
  fill: none;
  stroke: ${COLOR_MAP.loader.main};
  stroke-linecap: round;
  stroke-width: 10px;
  transition: stroke-dashoffset 0.8s linear;
`;

const Path1 = styled(Path)`
  stroke-dasharray: 680 2000;
  stroke-dashoffset: ${({ dashOffset }) => (dashOffset ? 0 : 700)};
  stroke-linejoin: miter;
  transition: 1.2s linear 0.1s;
`;

const Path2 = styled(Path)`
  stroke-dasharray: 260 1000;
  stroke-dashoffset: ${({ dashOffset }) => (dashOffset ? 0 : 261)};
  transition: 0.2s linear 1.1s;
`;

const Path3 = styled(Path)`
  stroke-dasharray: 300 1000;
  stroke-dashoffset: ${({ dashOffset }) => (dashOffset ? 0 : 301)};
  stroke-linejoin: bevel;
  transition: 0.5s linear 1.3s;
`;

const Path4 = styled(Path)`
  stroke-dasharray: 600 1000;
  stroke-dashoffset: ${({ dashOffset }) => (dashOffset ? 0 : 601)};
  stroke-linejoin: bevel;
  transition: 0.4s linear 1.5s;
`;

interface LoaderProps {
  isLoading: boolean;
}

export const Loader = ({ isLoading }: LoaderProps) => {
  const [dashOffsetIsNull, setDashOffsetIsNull] = useState(false); // Adjust this value based on your needs
  const [isLoadingEnd, setIsLoadingEnd] = useState(false);

  useEffect(() => {
    // setDashOffsetIsNull(true); // Set dash offset to 0 for starting animation

    // Toggle dashOffsetIsNull every 1.5 seconds
    const intervalId = setInterval(() => {
      setDashOffsetIsNull((prev) => !prev);
    }, 2300);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!isLoading) {
      timeoutId = setTimeout(() => {
        setIsLoadingEnd(true);
      }, 1000); // Adjust the delay as needed
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoading]);

  useEffect(() => {
    // Update body overflow when loader state changes
    if (isLoading) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }
  }, [isLoading]);

  return !isLoadingEnd ? (
    <PreloaderScreen hide={!isLoading}>
      <PreloaderWrapper>
        <Svg id="legit-svg" data-name="legit" xmlns="http://www.w3.org/2000/svg" viewBox="6.935 66.925 254.142 223.633">
          <Path1
            dashOffset={dashOffsetIsNull}
            // eslint-disable-next-line
            d="M 134.234 281.876 L 13.627 138.131 L 59.359 75.506 L 208.267 75.506 L 254.362 137.593 L 134.234 281.876 Z"
          />
          <Path2 dashOffset={dashOffsetIsNull} d="M 17.328 137.781 L 249.381 137.781" />
          <Path3
            dashOffset={dashOffsetIsNull}
            d="M 60.833 78.306 L 91.892 139.654 L 134.386 73.204 L 177.449 138.918 L 207.799 78.417"
          />
          <Path4 dashOffset={dashOffsetIsNull} d="M 178.843 137.838 L 134.234 275.154 L 89.9 136.133" />
        </Svg>
      </PreloaderWrapper>
    </PreloaderScreen>
  ) : null;
};

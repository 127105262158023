import styled from 'styled-components';
import { truthfulAdaptive } from '../../../componentUI/screen';

// TODO: Rewrite all styles with project constants like colors, Text, Title
export const Container = styled.div`
  width: 100%;
  padding: 60px 16px;

  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  gap: 40px;

  ${truthfulAdaptive.minWidth.tablet} {
    flex-direction: row;
    padding: 80px 80px 80px 80px;
    gap: 20px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    gap: 60px;
    padding: 160px 80px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    padding: 100px 80px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;

  ${truthfulAdaptive.maxWidth.tablet} {
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #faf7f1;
  padding: 24px;
  box-shadow: 0 5px 14px #00000014;
  border-radius: 12px;
  margin-bottom: auto;
  min-width: 280px;

  ${truthfulAdaptive.maxWidth.tablet} {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  color: #000000;
  font-family: Montserrat, sans-serif;
  margin: 0;

  :before {
    content: '';
    display: block;
    width: 80px;
    height: 6px;
    background-color: #cdab71;
    transition: width 0.5s ease-out;
    margin: 0 0 15px;
  }
`;

export const Description = styled.div`
  font-size: 17px;
  color: #4a4a4a;
  line-height: 1.625;
  font-family: Open Sans, sans-serif;
`;

export const Rate = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  font-family: Montserrat, sans-serif;
`;

export const Stars = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 3px;
`;

export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 24px;

  background: #fff;
  box-shadow: 0 5px 14px #00000014;
  border-radius: 12px;

  @media (max-width: 1024px) {
    padding: 15px;
  }
`;

export const SlideTitle = styled.p`
  font-size: 19px;
  font-weight: 600;
  line-height: 1.2;
  color: #000000;
  font-family: Montserrat, sans-serif;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const SlideDescription = styled.div`
  font-size: 17px;
  font-weight: 400;
  line-height: 1.2;
  color: #000000;
  font-family: Open Sans, sans-serif;

  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;

export const SlideName = styled.p`
  color: #989898;
  font-size: 14px;
  font-weight: 600;
`;

export const NextButton = styled.button`
  border: none;
  background: transparent;
  padding: 5px;
  cursor: pointer;
`;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageCommon } from '../PageCommon';
import { NumberOrder } from '../../components/NumberOrder';
import { WrapperOldCert } from './styled';
import { useGetCertContext } from '../../hooks/useGetCertContext';
import { ResponseCertType } from '../../types';
import { getCertificate } from '../request';
import { PreviewCertImg } from '../../components/PreviewCertImg';
import { isOldCert } from '../../../helpers/isOldCert';
import { Wrapper } from '../OrderPage/styled';
import { FullInfo } from '../../components/FullInfo';
import { Hr } from '../../../componentUI/simple/Hr';
import { Button } from '../../../componentUI/simple/Button';
import { useIsMobile } from '../../../hooks';
import { useIsDesktopXl } from '../../../hooks/useIsMobile';
import { Loader } from '../../../componentUI/simple/Loader';
import { PreviewCert } from '../../components/PreviewCert';
import { isPdf } from '../../../helpers/isPdf';
import { getOrientation } from '../../helpers/getOrientation';
import { sendEvent } from '../../../analytics';
import { PhotoV2 } from '../../components/PhotoV2';

export const UniversalOrderPage = () => {
  const isMobile = useIsMobile();
  const isDesktopXl = useIsDesktopXl();
  const navigate = useNavigate();

  const { setResponseStart, responseCert } = useGetCertContext();

  const params = useParams<{ id: string }>();

  // кейс с быстрой ссылкой
  const [isLoadingDirect, setIsLoadingDirect] = useState(Boolean(params.id && !responseCert));
  const [isVerticalOrientation, setVerticalOrientation] = useState<{
    isVerticalOrientation: boolean;
  }>({
    isVerticalOrientation: true,
  });
  const [isLoading, setIsLoading] = useState(isLoadingDirect);

  const loaderTime = 4000; // how long show loader

  useEffect(() => {
    if (!responseCert) {
      const startTime = Date.now();

      setIsLoadingDirect(true);
      setIsLoading(true);

      if (params.id) {
        getCertificate(params.id)
          .then((res) => {
            if (res.status === 204 || res.status === 404) {
              throw new Error('Certificate not found');
            }

            if (res.status !== 200) {
              throw new Error('Internal server error');
            }

            return res.json();
          })
          .then((res: ResponseCertType) => {
            setResponseStart(res);

            if (!res.certificateUrl) return;

            getOrientation(res.certificateUrl).then((orientationResult) => {
              setVerticalOrientation(orientationResult);
            });
          })
          .catch((error) => {
            if (error.message === 'Certificate not found') {
              navigate('/error?status=404', { replace: true });
            } else {
              navigate('/error?status=500', { replace: true });
            }
          })
          .finally(() => {
            setIsLoading(false);
            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(0, loaderTime - elapsedTime);

            // Ensure the loader is shown for at least 4 seconds
            setTimeout(() => {
              setIsLoadingDirect(false);
            }, remainingTime);
          });
      }
    }

    if (responseCert?.certificateUrl) {
      getOrientation(responseCert.certificateUrl).then((orientationResult) => {
        setVerticalOrientation(orientationResult);
      });
    }
  }, []);

  return (
    <>
      <Loader isLoading={isLoadingDirect} />
      {!isLoading && responseCert && (
        <PageCommon>
          {isOldCert(responseCert) ? (
            <>
              <NumberOrder />
              <WrapperOldCert>
                {isPdf(responseCert) ? (
                  <PreviewCert isVerticalOrientation={isVerticalOrientation.isVerticalOrientation} />
                ) : (
                  <PreviewCertImg />
                )}
              </WrapperOldCert>
            </>
          ) : (
            <>
              <NumberOrder />
              <Wrapper isVerticalOrientation={isVerticalOrientation.isVerticalOrientation}>
                {isPdf(responseCert) ? (
                  <PreviewCert isVerticalOrientation={isVerticalOrientation.isVerticalOrientation} />
                ) : (
                  <PreviewCertImg />
                )}

                <FullInfo />
                <PhotoV2 isVerticalOrientation={isVerticalOrientation.isVerticalOrientation} />

                {isMobile && (
                  <div style={{ width: '100%' }}>
                    <div style={{ height: '28px' }} />
                    <Hr width="100%" />
                    <Button
                      isStretch
                      style={{ minWidth: !isDesktopXl ? '100%' : '480px', marginTop: '40px' }}
                      onClick={() => {
                        sendEvent('Button', 'VerifyAnotherCertificate');
                        navigate('/');
                        window.scrollTo(0, 0);
                      }}
                    >
                      VERIFY ANOTHER CERTIFICATE
                    </Button>
                  </div>
                )}
              </Wrapper>
            </>
          )}
        </PageCommon>
      )}
    </>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Picture } from '../../../componentUI/complex/Picture';
import { LogoPng } from '../SearchBlock/assets';
import { Footer } from '../../components/Footer';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { InfoBanner } from '../../components/InfoBanner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${COLOR_MAP.background.darkGrey};
`;

const HeaderPictureWrapper = styled.div`
  align-self: flex-start;

  padding: 10px 18px;

  ${truthfulAdaptive.minWidth.tablet} {
    padding: 16px 40px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 26px 80px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    width: 1440px;
    align-self: center;
  }
`;

export const PageCommon = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <InfoBanner />
        <HeaderPictureWrapper>
          <Picture url={LogoPng} width={48} height={36} onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
        </HeaderPictureWrapper>
      </Container>
      <div style={{ flexGrow: 100 }}>{children}</div>
      <Footer />
    </>
  );
};

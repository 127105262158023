import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { LeftNav } from './LeftNav';
import { RightNav } from './RightNav';
import './image-gallery.scss';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { PhotosEntityType } from '../../../Certificate/types';

/**
 * модалка для отображения фотографий в индексе когда их больше одной
 */

type Props = {
  filesImages: PhotosEntityType[];
  initialSlide: number;
};

export const ViewingIndexPhotos = ({ filesImages, initialSlide }: Props) => {
  const { isDesktopSNew } = useMatchMedia();
  const [maxImageHeight, setMaxImageHeight] = useState(0);

  useEffect(() => {
    // Calculate the maximum height of all images
    const loadImageHeights = async () => {
      const heights = await Promise.all(
        filesImages.map((image) => {
          return new Promise<number>((resolve) => {
            const img = new Image();
            img.src = image.url;
            img.onload = () => resolve(img.height);
          });
        }),
      );
      setMaxImageHeight(Math.max(...heights));
    };

    loadImageHeights();
  }, [filesImages]);

  const images = filesImages.map((element) => {
    return {
      original: `${element.url || ''}`,
      thumbnail: `${element.url || ''}`,
      renderItem: () => (
        <div className="image-wrapper">
          <img
            src={element.url || ''}
            alt={element.index_name}
            className="image-gallery-image"
            style={{ height: maxImageHeight }}
          />
        </div>
      ),
      // originalHeight: isDesktopSNew ? 682 : 512,
    };
  });

  return (
    <ImageGallery
      startIndex={initialSlide}
      showNav={!isDesktopSNew}
      showPlayButton={false}
      showBullets={false}
      showFullscreenButton={false}
      items={images}
      showThumbnails={filesImages.length > 1}
      renderLeftNav={(onClick, disabled) => <LeftNav onClick={onClick} disabled={disabled} />}
      renderRightNav={(onClick, disabled) => <RightNav onClick={onClick} disabled={disabled} />}
    />
  );
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, ControlContainer, SecondTitleStyled } from './styled';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../componentUI/simple/Title';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Button } from '../../../componentUI/simple/Button';
import { useIsMobile } from '../../../hooks';
import { useGetCertContext } from '../../hooks/useGetCertContext';
import { getCertificate } from '../request';
import { ResponseType } from '../../types';
import { InputWithError } from '../../../componentUI/complex/InputWithError';
import { sendEvent } from '../../../analytics';

export const SearchBlock = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { setResponseStart } = useGetCertContext();

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const findHandler = () => {
    if (!search) {
      setErrorMessage('Enter the unique code value');
      return;
    }

    setIsLoading(true);

    // GA
    sendEvent('Button', 'FindCertificate');

    // getCertificate('B1190382185475')
    // getCertificate('B3707954782548')
    getCertificate(search)
      .then((res) => {
        if (res.status === 204 || res.status === 404) {
          navigate('/error?status=404', { replace: true });
          return;
        }

        if (res.status !== 200) {
          navigate('/error?status=500', { replace: true });
          return;
        }

        return res.json();
      })
      .then((res: ResponseType) => {
        if (res) {
          setResponseStart(res);
          navigate(`/${search}`);
        }
      })
      .catch(() => {
        setErrorMessage((prev) => {
          return prev || 'Something went wrong. Please try again.';
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeInput = (value: string) => {
    if (errorMessage) setErrorMessage('');

    setSearch(value);
  };

  return (
    <Container>
      <Title
        size={TITLE_SIZE.h1}
        colorText={COLOR_MAP.text.white}
        weight={TITLE_WEIGHT.bold}
        margins={[0, 0, 20, 0]}
        style={{ letterSpacing: '6px', textAlign: 'center', fontSize: isMobile ? '32px' : '48px' }}
      >
        VERIFY YOUR CERTIFICATE
      </Title>
      {!isMobile && (
        <SecondTitleStyled size={TITLE_SIZE.h6} colorText={COLOR_MAP.text.grey} weight={TITLE_WEIGHT.normal}>
          Search for your authenticity certificate by entering its unique code in the search bar below
        </SecondTitleStyled>
      )}
      <ControlContainer
        onSubmit={(e) => {
          e.preventDefault();

          if (search.trim() !== '') {
            findHandler();
          }
        }}
      >
        <InputWithError
          errorMessage={errorMessage}
          isStretch
          onChange={onChangeInput}
          value={search}
          placeholder="Unique code"
          type="search"
          autoComplete="on"
          disabled={isLoading}
        />
        <Button
          isStretch
          style={{ minWidth: '200px', flexShrink: '10', maxHeight: '44px' }}
          disabled={isLoading}
          type="submit"
        >
          FIND CERTIFICATE
        </Button>
      </ControlContainer>
    </Container>
  );
};

import styled from 'styled-components';
import { truthfulAdaptive } from '../../../componentUI/screen';

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0ede3;

  padding: 40px 16px;
  flex-direction: column;
  gap: 28px;

  ${truthfulAdaptive.minWidth.tablet} {
    flex-direction: row;
    padding: 80px 40px;
    gap: 20px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    gap: 60px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  gap: 28px;

  ${truthfulAdaptive.minWidth.tablet} {
    gap: 40px;
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { Text, TEXT_WEIGHT } from '../../../componentUI/simple/Text';
import { Picture } from '../../../componentUI/complex/Picture';
import { useGetCertContext } from '../../hooks/useGetCertContext';
import { useIsMobile, useIsTablet, useStyledModal } from '../../../hooks';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { isResponseCert } from '../../types';
import { sendEvent } from '../../../analytics';
import { ViewingIndexPhotos } from '../../../componentUI/complex/ViewingIndexPhotos';
/* eslint-disable camelcase */

export const PhotoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${truthfulAdaptive.minWidth.tablet} {
    justify-content: flex-start;

    gap: 20px;
    max-width: 9999999999px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    max-width: 420px;
    gap: 20px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    margin-right: 0;

    max-width: 400px;
    gap: 20px;
  }
`;

export const PhotoContainer = styled.div<{ isVerticalOrientation: boolean }>`
  ${truthfulAdaptive.minWidth.desktop1440} {
    ${({ isVerticalOrientation }) => (isVerticalOrientation ? '' : 'grid-column: span 2;')}
  }
`;

export const SkeletonPhotoContainer = styled.div<{ isVerticalOrientation: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${truthfulAdaptive.minWidth.desktop1440} {
    ${({ isVerticalOrientation }) => (isVerticalOrientation ? '' : 'grid-column: span 2;')}
  }
`;

const PictureStyled = styled(Picture)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  pointer-events: none;
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const PhotoV2 = ({ isVerticalOrientation }: { isVerticalOrientation: boolean }) => {
  const { responseCert } = useGetCertContext();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const [Modal, toggleModal] = useStyledModal();
  const [initialSlide, setInitialSlide] = useState(0);

  const filesImages = isResponseCert(responseCert) && responseCert?.photos ? responseCert.photos : [];

  let pictureSize: number;
  if (isMobile) {
    pictureSize = 160;
  } else if (isTablet) {
    pictureSize = 100;
  } else {
    pictureSize = 120;
  }

  let maxWidth: string;
  let maxHeight: string;
  if (isMobile) {
    maxWidth = '160px';
    maxHeight = '160px';
  } else if (isTablet) {
    maxWidth = '100px';
    maxHeight = '100px';
  } else {
    maxWidth = '120px';
    maxHeight = '120px';
  }

  return (
    <PhotoContainer isVerticalOrientation={isVerticalOrientation}>
      <Text weight={TEXT_WEIGHT.bold} margins={[0, 0, 8, 0]}>
        Submitted photos:
      </Text>
      <PhotoWrapper>
        {isResponseCert(responseCert) &&
          responseCert?.photos!.map(({ index_name, url }, idx) => (
            <StyledButton
              key={index_name}
              style={{
                maxWidth,
                maxHeight,
                cursor: 'pointer',
                overflow: 'hidden',
              }}
              onClick={() => {
                sendEvent('Button', 'PhotoViewer');
                toggleModal();
                setInitialSlide(idx);
              }}
            >
              <PictureStyled
                url={url}
                width={pictureSize}
                height={pictureSize}
                alt={index_name}
                withSkeleton
                style={{ margin: '0', minHeight: pictureSize, minWidth: pictureSize, borderRadius: '6px' }}
              />
              <Text margins={[8, 0, 0, 0]} style={{ wordBreak: 'break-word', textAlign: 'center' }}>
                {index_name}
              </Text>
            </StyledButton>
          ))}
      </PhotoWrapper>

      {filesImages.length > 0 && (
        <Modal onClose={toggleModal} isPhoto>
          <ViewingIndexPhotos filesImages={filesImages} initialSlide={initialSlide} />
        </Modal>
      )}
    </PhotoContainer>
  );
};

import React from 'react';
import { SearchBlock } from './container/SearchBlock';
import { InfoBlock } from './components/InfoBlock';
import { LearnMoreBlock } from './components/LearnMoreBlock';
// import { UsefulBlock } from './components/UsefulBlock';
// import { Testimonials } from './components/Testimonials';
import { Featured } from './components/Featured';
import { PageCommon } from './container/PageCommon';
import { TestimonialsV2 } from './components/TestimonialsV2';

export const Certificate = () => {
  return (
    <PageCommon>
      <>
        <SearchBlock />
        <InfoBlock />
        <LearnMoreBlock />
        {/* <UsefulBlock /> */}
        <Featured />
        {/* <Testimonials /> */}
        <TestimonialsV2 />
      </>
    </PageCommon>
  );
};

export const reviews = [
  {
    title: 'Reseller-friendly',
    description:
      "Wonderful experience! I was hesitant to sell my Cartier jewelry online due to authenticity concerns, but LegitGrails confirmed it's genuine, giving me confidence to proceed.",
    name: 'Rose T',
    stars: '5',
  },
  {
    title: 'Always satisfied',
    description:
      "LegitGrails has consistently provided excellent service. Their turnaround time matches what they promise. I'll keep using them for my reselling business.",
    name: 'Olivia B',
    stars: '5',
  },
  {
    title: 'Quick and accurate',
    description:
      "I'm pleased with the service and what I received. LegitGrails is worth every dollar, they are reliable and thorough!! Finally I am sure I don't buy a fake",
    name: 'Alejandro D',
    stars: '5',
  },
  {
    title: 'Works great for resellers',
    description:
      "It's so easy to use the LegitGrails platform for authentication, I love it - all my authentications are visible with outcomes and statuses. Thanks team!",
    name: 'Stacey M',
    stars: '5',
  },
  {
    title: 'Extremely satisfied',
    description:
      'Used LegitGrails to authenticate my Chanel bag, and they were faster than expected. Quick responses, I will use you again, thank you!',
    name: 'Emily G',
    stars: '5',
  },
  {
    title: 'Very fast and professional',
    description:
      'Highly recommend LegitGrails authentication for anyone needing quick and accurate check of luxury handbags and shoes. Thanks for the great service!',
    name: 'Rebecca R',
    stars: '5',
  },
  {
    title: 'Excellent!!!',
    description:
      "Used LegitGrails twice now - it's reassuring to trust them when buying an expensive luxury item. Their responses are quick too!",
    name: 'Sarah R',
    stars: '5',
  },
  {
    title: 'Highly recommend',
    description:
      "Fantastic service! LegitGrails's quick response saved me from buying a fake bag... Highly recommend their certificates for a peace of mind.",
    name: 'Daniel L',
    stars: '5',
  },
  {
    title: 'Protected from a fake',
    description:
      'Thanks a lot for saving me from purchasing a fake bag. The seller eagerly sent many photos, but the truth came out in the results. Appreciate the excellent service!',
    name: 'Hannah O',
    stars: '5',
  },
  {
    title: 'Much appreciated!',
    description:
      "For a few years now, I've relied on LegitGrails, and they consistently deliver. I have an eye for authenticating vintage bags but LegitGrails adds that extra layer of certainty.",
    name: 'Maxime J',
    stars: '5',
  },
  {
    title: 'I am a high-end brand reseller...',
    description:
      'My go-to for verifying luxury items online! LegitGrails always helps me ensure authenticity, and as a business owner, the fees are tax-deductible! 🙌🏼',
    name: 'Kim A',
    stars: '5',
  },
  {
    title: 'LegitGrails instills buyer confidence.',
    description:
      'Discovered LegitGrails through a buyer after a dispute. Their inspections and certificates quickly resolved our issues. Fast customer service adds value',
    name: 'John G',
    stars: '5',
  },
  {
    title: 'Won a dispute and got my money back',
    description:
      "LegitGrails helped me get a refund for a fake Fendi bag I purchased online. It's so revealing to get support after you think you lost the money!!!",
    name: 'Alvy N',
    stars: '5',
  },
  {
    title: 'Vintage Chanel authenticated',
    description:
      "I got LegitGrails' 24-hour service, received timely email with detailed authentication explanation. Highly recommend and will use again!",
    name: 'Justin S',
    stars: '5',
  },
];

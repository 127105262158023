import React from 'react';
import { Container, PictureStyled, TextContainer } from './styled';
import { GrowWebp } from './assets';
import { Title, TITLE_SIZE } from '../../../componentUI/simple/Title';
import { Text } from '../../../componentUI/simple/Text';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Button } from '../../../componentUI/simple/Button';
import { useIsMobile } from '../../../hooks';
import { sendLinkEvent } from '../../../analytics/analytics';

const text =
  'Are you an online reseller or a business owner that deals with branded items?\n' +
  "Let's work together to get rid of fakes.";

export const LearnMoreBlock = () => {
  const isMobile = useIsMobile();

  // GA
  const onLinkClick = () => {
    sendLinkEvent('LearnMore');
  };

  return (
    <Container>
      <TextContainer>
        <Title
          size={TITLE_SIZE.h3}
          colorText={COLOR_MAP.background.darkGrey}
          style={{ textAlign: isMobile ? 'center' : undefined }}
        >
          Grow your Business with LegitGrails
        </Title>
        <Text colorText={COLOR_MAP.text.darkGrey} style={{ textAlign: isMobile ? 'center' : undefined }}>
          {text}
        </Text>
        <div style={{ maxWidth: isMobile ? '100%' : '260px', width: '100%' }}>
          <Button
            // @ts-ignore
            as="a"
            isStretch
            href="https://legitgrails.com/pages/business-solutions"
            target="_blank"
            style={{ textDecoration: 'none' }}
            onClick={() => onLinkClick}
          >
            LEARN MORE
          </Button>
        </div>
      </TextContainer>
      <PictureStyled url={GrowWebp} width="100%" />
    </Container>
  );
};

import React, { useRef } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// @ts-ignore eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import { Navigation, Pagination } from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
import { useIsDesktopS } from '../../../hooks';
import './styles.css';
import {
  Container,
  ContentWrapper,
  Description,
  NextButton,
  Rate,
  Slide,
  SlideDescription,
  SlideName,
  SlideTitle,
  Stars,
  Title,
  TitleWrapper,
} from './styled';
import { renderStars } from './renderStars';
import { reviews } from './data';

interface SwiperRef {
  slideNext?: () => void;
}

const StyledSwiper = styled(Swiper)`
  .swiperTestimonials {
    justify-content: flex-start;
  }

  .swiper-slide {
    align-items: flex-start;
    text-align: left;
  }
`;

// TODO: Rewrite all styles with project constants like colors, Text, Title
export const TestimonialsV2 = () => {
  const swiperRef = useRef<SwiperRef>();
  const isDesktopS = useIsDesktopS();

  const handleNextButtonClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext?.();
    }
  };

  return (
    <Container>
      <ContentWrapper>
        <TitleWrapper>
          <Title>LegitGrails</Title>
          <Description>The most advanced authentication service</Description>
          <Stars>{renderStars('4.7')}</Stars>
          <Rate>4.7 out of 5</Rate>
        </TitleWrapper>

        <StyledSwiper
          className="swiperTestimonials"
          modules={[Navigation, Pagination]}
          loop
          centeredSlides={false}
          spaceBetween={15}
          slidesPerView={1}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            900: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            1250: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          onBeforeInit={(swiper: undefined) => {
            swiperRef.current = swiper;
          }}
          pagination={{
            clickable: true,
          }}
        >
          {reviews.map((review, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={index}>
              <Slide>
                <Stars>{renderStars(review.stars)}</Stars>
                <SlideTitle>{review.title}</SlideTitle>
                <SlideDescription>{review.description}</SlideDescription>
                <SlideName>{review.name}</SlideName>
              </Slide>
            </SwiperSlide>
          ))}
        </StyledSwiper>

        {!isDesktopS && (
          <NextButton onClick={handleNextButtonClick}>
            <svg width="20" height="39" viewBox="0 0 20 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 37L18 19.5L2 2"
                stroke="#CBAD73"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NextButton>
        )}
      </ContentWrapper>
    </Container>
  );
};

export const getCertificate = (id: string) => {
  return fetch(`https://6fmw7dpqu4.execute-api.eu-west-2.amazonaws.com/1/certificate?id=${id.toUpperCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      // 'lg-api-key': 'c7f65b3e-08d1-486b-88e2-ce3dda6ddd1c:Y/fH5F292HvF2WDAHrpdLrzivqSaXR',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImNlcnRpZmljYXRlcyIsImlhdCI6MTUxNjIzOTAyMn0.-QQgTpqcXqHSV71z4C4mDrf2m2Askl1gRYVK5fpx_5U',
    },
  });
};

import styled from 'styled-components';
import { truthfulAdaptive } from '../../../componentUI/screen';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  background-color: #32312f;
`;

export const Brands = styled.div`
  display: grid;
  align-items: flex-end;
  justify-items: center;

  row-gap: 32px;
  column-gap: 132px;

  grid-template-columns: 1fr;

  ${truthfulAdaptive.minWidth.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';

interface BaseSkeletonProps {
  width?: string;
  height?: string;
}

interface TextSkeletonProps extends BaseSkeletonProps {
  fontSize?: string;
  borderRadius?: string;
}

interface RoundedSkeletonProps extends BaseSkeletonProps {
  borderRadius?: string;
}

interface CircularSkeletonProps extends BaseSkeletonProps {}

type SkeletonVariant = 'text' | 'circular' | 'rounded' | 'rectangular';

interface SkeletonProps extends BaseSkeletonProps {
  variant: SkeletonVariant;
}

// const pulseAnimation = keyframes`
//     0% {
//         opacity: 0.6;
//     }
//     50% {
//         opacity: 1;
//     }
//     100% {
//         opacity: 0.6;
//     }
// `;

const gradientAnimation = keyframes`
  to {
    background-position-x: -20%;
  }
`;

const SkeletonText = styled.div<TextSkeletonProps>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '16px')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '4px')};
  line-height: 1.5;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};

  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    ${COLOR_MAP.skeleton.content1};
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: ${gradientAnimation} 1s ease-in-out infinite;
`;

const SkeletonCircular = styled.div<CircularSkeletonProps>`
  width: ${(props) => (props.width ? props.width : '24px')};
  height: ${(props) => (props.height ? props.height : '24px')};
  border-radius: 50%;

  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    ${COLOR_MAP.skeleton.content1};
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: ${gradientAnimation} 1s ease-in-out infinite;
`;

const SkeletonRectangular = styled.div<BaseSkeletonProps>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '16px')};

  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    ${COLOR_MAP.skeleton.content1};
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: ${gradientAnimation} 1s ease-in-out infinite;
`;

const SkeletonRounded = styled.div<RoundedSkeletonProps>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '16px')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '4px')};

  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    ${COLOR_MAP.skeleton.content1};
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: ${gradientAnimation} 1s ease-in-out infinite;
`;

export const Skeleton = ({ variant = 'rounded', ...rest }: SkeletonProps) => {
  switch (variant) {
    case 'text':
      return <SkeletonText {...(rest as TextSkeletonProps)} />;
    case 'circular':
      return <SkeletonCircular {...(rest as CircularSkeletonProps)} />;
    case 'rectangular':
      return <SkeletonRectangular {...rest} />;
    case 'rounded':
      return <SkeletonRounded {...(rest as RoundedSkeletonProps)} />;
    default:
      return null;
  }
};

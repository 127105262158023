import styled from 'styled-components';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { Picture } from '../../../componentUI/complex/Picture';

export const Container = styled.div`
  width: 100%;
  padding: 60px 16px;

  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  gap: 40px;

  ${truthfulAdaptive.minWidth.tablet} {
    flex-direction: row;
    padding: 80px 80px 80px 40px;
    gap: 20px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    gap: 60px;
    padding: 160px 80px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    padding: 100px 80px;
  }
`;

export const PictureStyled = styled(Picture)`
  max-width: 300px;

  ${truthfulAdaptive.minWidth.tablet} {
    max-width: 270px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    max-width: 472px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    max-width: 592px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 580px;
  align-items: center;
  gap: 20px;
  white-space: pre-wrap;

  ${truthfulAdaptive.maxWidth.desktopS} {
  }

  ${truthfulAdaptive.minWidth.tablet} {
    align-items: flex-start;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    gap: 40px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

import styled from 'styled-components';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../componentUI/screen';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;

  background-color: ${COLOR_MAP.background.darkGrey};

  ${truthfulAdaptive.minWidth.tablet} {
    padding: 40px 40px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    padding: 50px 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  background-color: ${COLOR_MAP.background.darkGrey};

  ${truthfulAdaptive.minWidth.tablet} {
    width: 100%;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    display: grid;
    grid-template-columns: 2fr 2px 1fr;
    align-items: flex-start;
    justify-items: center;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    max-width: 1440px;
    padding: 0 80px;
  }
`;

export const Links = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  ${truthfulAdaptive.minWidth.tablet} {
    width: 100%;
    display: grid;
    //justify-content: space-between;
    align-items: flex-start;

    grid-template-columns: 1fr 1fr 1fr;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    display: grid;
    justify-content: space-between;

    grid-template-columns: 1fr 1fr 1fr;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

export const LinkBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${truthfulAdaptive.minWidth.tablet} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 16px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

export const Line = styled.div`
  border: 1px #404147 solid;
  width: 40%;

  ${truthfulAdaptive.minWidth.tablet} {
    width: 100%;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    height: 165px;
  }
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-left: 0;
  align-items: center;

  ${truthfulAdaptive.minWidth.tablet} {
  }

  ${truthfulAdaptive.minWidth.desktop} {
    align-items: flex-end;
    margin-left: 0;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    margin-left: 20px;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  gap: 28px;
  margin-bottom: 24px;

  ${truthfulAdaptive.minWidth.tablet} {
    width: 100%;
    justify-content: space-between;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    width: auto;

    justify-content: flex-end;
  }
`;

export const ConditionalBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${truthfulAdaptive.minWidth.tablet} {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    width: auto;
    flex-direction: column;
    align-items: flex-end;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

export const CopyrightInfo = styled.div`
  display: flex;
  flex-direction: column;

  ${truthfulAdaptive.minWidth.tablet} {
    align-items: flex-end;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    min-width: 350px;
  }
`;

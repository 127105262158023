import React from 'react';
// eslint-disable-next-line import/no-named-default
import styled from 'styled-components';
import { pdfjs, Document, Page } from 'react-pdf';
// eslint-disable-next-line import/no-named-default
import { SecondButton } from '../../../componentUI/simple/Button';
import { DownloadIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { useIsMobile } from '../../../hooks';
import { useGetCertContext } from '../../hooks/useGetCertContext';

import './style.css';
import { isResponseCert } from '../../types';
import { Skeleton } from '../../../componentUI/complex/Skeleton';
import { sendLinkEvent } from '../../../analytics/analytics';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const Container = styled.div<{ isOld: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isOld }) => (isOld ? 'align-items: center;' : 'align-items: flex-end;')}

  width: 100%;

  ${truthfulAdaptive.minWidth.tablet} {
    width: auto;

    flex-direction: column;
    ${({ isOld }) => (isOld ? 'align-items: center;' : 'align-items: flex-start;')}
  }
`;

const getWidth = (isVerticalOrientation: boolean) => {
  const widthScreen = document.documentElement.clientWidth;

  if (widthScreen < 720) {
    return widthScreen - 32;
  }

  if (widthScreen < 1200) {
    return isVerticalOrientation ? 306 : 434;
  }

  if (widthScreen < 1440) {
    return isVerticalOrientation ? 418 : 592;
  }

  return isVerticalOrientation ? 480 : 678;
};

const HeightPdf = styled.div<{ isVerticalOrientation: boolean }>`
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06), 0 3px 5px 0 rgba(150, 112, 38, 0.04);

  overflow: hidden;
  ${({ isVerticalOrientation }) => {
    const widthScreen = document.documentElement.clientWidth;
    const verticalOrientation = (widthScreen - 32) * 1.5;
    const horizonOrientation = (widthScreen - 32) / 1.5;

    return isVerticalOrientation ? `max-height: ${verticalOrientation}px;` : `max-height: ${horizonOrientation}px;`;
  }}

  ${truthfulAdaptive.minWidth.tablet} {
    ${({ isVerticalOrientation }) => (isVerticalOrientation ? 'max-height: 459px;' : 'max-height: 306px;')}
  }

  ${truthfulAdaptive.minWidth.desktop} {
    ${({ isVerticalOrientation }) => (isVerticalOrientation ? 'max-height: 617px;' : 'max-height: 418px;')}
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    ${({ isVerticalOrientation }) => (isVerticalOrientation ? 'max-height: 720px;' : 'max-height: 480px;')}
  }
`;

type Props = { isVerticalOrientation: boolean };

export const PreviewCert = ({ isVerticalOrientation }: Props) => {
  const isMobile = useIsMobile();

  const { responseCert } = useGetCertContext();

  if (!responseCert?.certificateUrl) return null;

  // GA
  const onLinkClick = () => {
    sendLinkEvent('DownloadCertificate');
  };

  return (
    <Container isOld={!isResponseCert(responseCert)}>
      <div style={{ width: '100%', marginBottom: '32px', display: 'flex', justifyContent: 'center' }}>
        {responseCert.certificateUrl && (
          <Document
            file={responseCert.certificateUrl}
            loading={
              <HeightPdf isVerticalOrientation={isVerticalOrientation}>
                <Skeleton variant="rectangular" height="720px" width={`${getWidth(isVerticalOrientation)}px`} />
              </HeightPdf>
            }
          >
            <HeightPdf isVerticalOrientation={isVerticalOrientation}>
              <Page
                pageNumber={1}
                className="my-pdf-page"
                width={getWidth(isVerticalOrientation)}
                height={300}
                devicePixelRatio={4}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </HeightPdf>
          </Document>
        )}
      </div>

      <SecondButton
        // @ts-ignore
        as="a"
        href={responseCert.certificateUrl}
        target="_blank"
        isStretch={isMobile}
        onClick={() => onLinkClick}
      >
        <DownloadIcon />
        DOWNLOAD CERTIFICATE
      </SecondButton>
    </Container>
  );
};

import styled from 'styled-components';
import { truthfulAdaptive } from '../../../componentUI/screen';

export const Wrapper = styled.div<{ isVerticalOrientation: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  -webkit-gap: 32px;
  justify-content: center;

  padding: 0 16px;
  margin-bottom: 40px;

  ${truthfulAdaptive.minWidth.tablet} {
    padding: 0 40px;
    margin-bottom: 60px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    display: grid;
    gap: 32px;
    -webkit-gap: 32px;
    padding: 0 80px;

    ${({ isVerticalOrientation }) =>
      isVerticalOrientation ? 'grid-template-columns: 418px 279px 279px;' : 'grid-template-columns: 592px 484px;'}

    ${({ isVerticalOrientation }) => (isVerticalOrientation ? 'align-items: flex-start;' : 'justify-content: center;')}
    
    margin-bottom: 80px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    ${({ isVerticalOrientation }) =>
      isVerticalOrientation ? 'grid-template-columns: 480px 305px 403px;' : 'grid-template-columns: 678px 556px;'}

    gap: 32px 46px;
    -webkit-gap: 32px 46px;
  }
`;

export const WrapperOldCert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 0 16px;
  margin-bottom: 40px;

  ${truthfulAdaptive.minWidth.tablet} {
    align-items: center;

    padding: 0 40px;
    margin-bottom: 60px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 0 80px;
    margin-bottom: 80px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

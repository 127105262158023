import React from 'react';
import styled from 'styled-components';
import { ErrorPropsType } from '../Certificate/types';
import { Picture } from '../componentUI/complex/Picture';
import { LogoPng } from '../Certificate/container/SearchBlock/assets';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../componentUI/simple/Title';
import { COLOR_MAP } from '../componentUI/colorsMap';
import { SecondButton } from '../componentUI/simple/Button';
import { Footer } from '../Certificate/components/Footer';
import { truthfulAdaptive } from '../componentUI/screen';
import { Img } from './Img';

const HeaderContainer = styled.div`
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${COLOR_MAP.background.darkGrey};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 100;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //padding-bottom: 100px;

  padding: 60px 16px;

  ${truthfulAdaptive.minWidth.tablet} {
    padding: 90px 40px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 100px 40px;
  }
`;

const Container = styled.div`
  min-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const DescriptionScreen = styled.div`
  padding: 40px 20px 20px 20px;

  display: flex;
  flex-direction: column;

  ${truthfulAdaptive.minWidth.desktop} {
    min-height: calc(100vh - 330px);
  }
`;

const HeaderPictureWrapper = styled.div`
  align-self: flex-start;

  padding: 10px 18px;

  ${truthfulAdaptive.minWidth.tablet} {
    padding: 16px 80px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 26px 80px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    width: 1440px;
    align-self: center;
  }
`;

export const Error = (
  props: ErrorPropsType = {
    errorTitle: 'Something went wrong.',
    errorMessage: 'Try again later or contact support.',
  },
) => {
  const { errorTitle, errorMessage } = props;

  return (
    <>
      <HeaderContainer>
        <HeaderPictureWrapper>
          <Picture url={LogoPng} width={48} height={36} />
        </HeaderPictureWrapper>
      </HeaderContainer>
      <Wrapper>
        <Container>
          <DescriptionScreen>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: '1',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', margin: '0 0 40px 0', alignItems: 'center', justifyContent: 'center' }}>
                <Img />
              </div>

              <Title
                size={TITLE_SIZE.h3}
                margins={[0, 0, 20, 0]}
                weight={TITLE_WEIGHT.normal}
                style={{ textAlign: 'center', maxWidth: '550px', wordBreak: 'break-word' }}
              >
                {errorTitle || 'Something went wrong.'}
              </Title>

              <div style={{ textAlign: 'center', maxWidth: '550px' }}>
                <Title
                  size={TITLE_SIZE.h5}
                  margins={[0, 0, 40, 0]}
                  style={{ textAlign: 'center', maxWidth: '550px' }}
                  colorText={COLOR_MAP.text.darkGrey}
                  weight={TITLE_WEIGHT.normal}
                >
                  {errorMessage || 'Try again later or contact support.'}
                </Title>
              </div>
              {/*  @ts-ignore */}
              <SecondButton as="a" href="/">
                GO BACK
              </SecondButton>
            </div>
          </DescriptionScreen>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
};

import React from 'react';
import styled from 'styled-components';

import { COLOR_MAP } from '../../colorsMap';
import { SIZE_STYLE, TEXT_SIZE, TEXT_WEIGHT } from '../Text/constant';
import { PropsLink } from './types';

const AnchorLink = styled.a<PropsLink>`
  font-family: 'Open Sans', sans-serif;
  color: ${({ colorText }) => colorText};
  ${({ size }) => SIZE_STYLE[size]};
  ${({ weight }) => `font-weight: ${weight}`};

  text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const LinkClassic = ({
  size = TEXT_SIZE.regular,
  colorText = COLOR_MAP.text.brandColor,
  weight = TEXT_WEIGHT.normal,
  isUnderlined = false,
  children,
  ...otherProps
}: Partial<PropsLink>) => {
  return (
    <AnchorLink colorText={colorText} size={size} weight={weight} isUnderlined={isUnderlined} {...otherProps}>
      {typeof children === 'string' ? children : children}
    </AnchorLink>
  );
};

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Skeleton } from '../Skeleton';

type Props = React.HTMLAttributes<HTMLElement> & {
  url: string;
  width: number | string;
  height?: number | string;
  alt?: string;
  withSkeleton?: boolean;
  forSwiper?: boolean; // need for Swiper second slider (under first slider)
  forSwiperMobile?: boolean; // need for Swiper second slider mobile version (under first slider)
};

const Loader = styled.div<{
  height?: number | string;
  width: number | string;
  forSwiper?: boolean;
  forSwiperMobile?: boolean;
}>`
  ${({ width }) => {
    if (typeof width === 'string') {
      return `width: ${width};`;
    }
    return width && `width: ${width - 1}px;`;
  }}

  ${({ height, forSwiper }) => {
    if (typeof height === 'string') {
      return forSwiper ? `height: ${height};` : `width: ${height};`;
    }
    if (height && forSwiper) {
      return `height: ${height - 1}px;`;
    }
    if (height) {
      return `width: ${height - 1}px;`;
    }
  }}

  height: ${(props) => (props.forSwiper ? '100%' : '')};
  height: ${(props) => (props.forSwiperMobile ? '94px' : '')};

  border: 1px rgba(0, 0, 0, 0) dashed;
`;

export const Picture = ({
  url,
  width,
  alt,
  height,
  withSkeleton = false,
  forSwiper = false,
  forSwiperMobile = false,
  ...otherProps
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [img, setImg] = useState<HTMLImageElement>();

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setIsLoading(false);

      setImg(image);
    };
    image.src = url;
  }, [url]);

  if (isLoading) {
    return (
      <Loader width={width} height={height} forSwiper={forSwiper} forSwiperMobile={forSwiperMobile} {...otherProps}>
        {withSkeleton && <Skeleton variant="rectangular" height="100%" width="100%" />}
      </Loader>
    );
  }

  return <img src={img?.src} alt={alt} width={width} height={height} style={{ display: 'block' }} {...otherProps} />;
};

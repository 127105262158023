import React, { useState } from 'react';
import styled from 'styled-components';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { AlertTriangleIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { Text, TEXT_WEIGHT } from '../../../componentUI/simple/Text';
import { CloseIconNew } from '../../../common/icons/SvgInline/SvgIcons_11';
import { LightButton } from '../../../componentUI/simple/Button';
import { truthfulAdaptive } from '../../../componentUI/screen';

const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  background: ${COLOR_MAP.background.goldGrey};
`;

const BannerContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  color: ${COLOR_MAP.text.white};

  ${truthfulAdaptive.minWidth.tablet} {
    padding: 16px 40px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 16px 80px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    width: 1440px;
    align-self: center;
    justify-content: center;
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const InfoBanner = () => {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;

  return (
    <BannerWrapper>
      <BannerContainer>
        <IconTextWrapper>
          <IconWrapper>
            <AlertTriangleIcon color={COLOR_MAP.accent.orange} />
          </IconWrapper>

          <Text colorText={COLOR_MAP.text.white}>
            Beware of websites impersonating LegitGrails certificates, make sure you are using this URL:{' '}
            <Text tag="span" colorText={COLOR_MAP.text.white} weight={TEXT_WEIGHT.bold}>
              https://certificates.legitgrails.com/
            </Text>
          </Text>
        </IconTextWrapper>

        <LightButton onClick={() => setVisible(false)}>
          <CloseIconNew />
        </LightButton>
      </BannerContainer>
    </BannerWrapper>
  );
};

import React from 'react';
import { Brands, Container } from './styled';
import { Title, TITLE_SIZE } from '../../../componentUI/simple/Title';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Picture } from '../../../componentUI/complex/Picture';
import { Forbes, Insider, Nice, Rapaport } from './assets';

export const Featured = () => {
  return (
    <Container>
      <Title colorText={COLOR_MAP.text.grey} size={TITLE_SIZE.h3} margins={[0, 0, 22, 0]}>
        FEATURED ON
      </Title>
      <Brands>
        <Picture url={Forbes} width={144} height={64} />
        <Picture url={Insider} width={175} height={64} />
        <Picture url={Nice} width={112} height={52} />
        <Picture url={Rapaport} width={183} height={64} />
      </Brands>
    </Container>
  );
};

import React from 'react';
import { Container, Outcome, OutcomeValue } from './styled';
import { Title, TITLE_SIZE } from '../../../componentUI/simple/Title';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { useGetCertContext } from '../../hooks/useGetCertContext';
import { isResponseCert } from '../../types';

export const InfoAuth = () => {
  const { responseCert } = useGetCertContext();

  return (
    <Container>
      <Outcome>
        <Title size={TITLE_SIZE.h6} style={{ padding: '10px 16px', textAlign: 'center', fontWeight: '500' }}>
          Authentication outcome
        </Title>
        <OutcomeValue>
          <Title
            size={TITLE_SIZE.h6}
            colorText={COLOR_MAP.text.white}
            style={{ textAlign: 'center', fontWeight: '500' }}
          >
            {isResponseCert(responseCert) ? responseCert?.outcome ?? 'No Outcome' : ''}
          </Title>
        </OutcomeValue>
      </Outcome>
    </Container>
  );
};

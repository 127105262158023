import styled from 'styled-components';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../componentUI/screen';

export const Container = styled.div`
  display: flex;
  align-self: center;
  width: 100%;

  margin-bottom: 28px;

  ${truthfulAdaptive.minWidth.tablet} {
    width: 100%;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 0 0 0 0;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    max-width: 1280px;
  }
`;

export const Outcome = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid ${COLOR_MAP.text.brandColor};
  width: 100%;

  ${truthfulAdaptive.minWidth.tablet} {
    width: auto;
    flex-direction: row;
  }

  ${truthfulAdaptive.minWidth.desktop} {
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

export const OutcomeSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 100%;

  ${truthfulAdaptive.minWidth.tablet} {
    width: auto;
    flex-direction: row;
  }

  ${truthfulAdaptive.minWidth.desktop} {
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

export const OutcomeValue = styled.div`
  display: inline-block;

  padding: 10px 16px;
  background-color: ${COLOR_MAP.text.brandColor};
  border-radius: 0 4px 4px 0;
`;

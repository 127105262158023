import { Dispatch, SetStateAction } from 'react';

export type PhotosEntityType = {
  url: string;
  index_name: string;
};

export type ResponseCertType = {
  format: 'new';
  id: string;
  external_id: string;
  category: string;
  brand: string;
  model_name: string | null;
  start_date: number;
  date_closed: number;
  customer_name: string;
  photos: Array<PhotosEntityType> | null;
  certificate_url: null | string;
  sku: null | string;
  certificateUrl: string;
  certificateCreatedAt: string;
  outcome: string;
};

export type ResponseOldCertType = {
  format: 'old';
  external_id: string;
  certificateUrl: string;
};

// A union type combining both formats
export type ResponseType = ResponseCertType | ResponseOldCertType;

export type CertContextType = {
  responseCert: ResponseType | null;
  setResponseStart: Dispatch<SetStateAction<ResponseType | null>>;
};

export type ErrorHandlerPropsType = {
  status: number;
};

export type ErrorPropsType = {
  errorTitle?: string;
  errorMessage?: string;
};

// type-guard for CertContextType
export const isResponseCert = (cert: ResponseType | null): cert is ResponseCertType => {
  return cert !== null && 'external_id' in cert;
};

import { pdfjs } from 'react-pdf';

interface OrientationResult {
  isVerticalOrientation: boolean;
}

export const getOrientation = async (url: string): Promise<OrientationResult> => {
  try {
    if (url.toLowerCase().endsWith('.pdf')) {
      const pdf = pdfjs.getDocument(url);
      const pdfDocument = await pdf.promise;
      const firstPage = await pdfDocument.getPage(1);
      const viewPort = firstPage.getViewport({ scale: 1 });
      return { isVerticalOrientation: viewPort.height > viewPort.width };
    }

    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        const isVertical = img.naturalWidth < img.naturalHeight;
        resolve({ isVerticalOrientation: isVertical });
      };
    });
  } catch (error) {
    return { isVerticalOrientation: true };
  }
};

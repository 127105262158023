import React from 'react';
import styled from 'styled-components';
import { Text, TEXT_WEIGHT } from '../../../componentUI/simple/Text';
import { useGetCertContext } from '../../hooks/useGetCertContext';
import { dataFormatterCert } from '../../../helpers/dataFormatter';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { isResponseCert } from '../../types';

const Container = styled.div`
  ${truthfulAdaptive.minWidth.tablet} {
    display: grid;
    width: 100%;

    grid-template-columns: 1fr 1fr;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    display: flex;
    width: auto;

    flex-direction: column;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    display: flex;
    flex-direction: column;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${truthfulAdaptive.minWidth.tablet} {
    display: grid;
    width: 100%;

    grid-template-columns: 1fr 1fr;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    display: flex;
    width: auto;

    flex-direction: column;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    display: flex;
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 20px;
`;

export const SkeletonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
`;

export const FullInfo = () => {
  const { responseCert } = useGetCertContext();

  const template = [
    { title: 'Unique code:', text: isResponseCert(responseCert) && responseCert.external_id },
    { title: 'Category:', text: isResponseCert(responseCert) && responseCert?.category },
    { title: 'Brand:', text: isResponseCert(responseCert) && responseCert?.brand },
    { title: 'Model:', text: isResponseCert(responseCert) && responseCert?.model_name },
    {
      title: 'Authentication submitted:',
      text:
        isResponseCert(responseCert) && responseCert?.start_date && dataFormatterCert(responseCert.start_date * 1000),
    },
    {
      title: 'Outcome provided:',
      text:
        isResponseCert(responseCert) && responseCert?.date_closed && dataFormatterCert(responseCert.date_closed * 1000),
    },
    {
      title: 'Certificate generated:',
      text:
        isResponseCert(responseCert) &&
        responseCert?.certificateCreatedAt &&
        dataFormatterCert(responseCert.certificateCreatedAt),
    },
  ];

  return (
    <Container>
      {template
        .filter(({ text }) => Boolean(text))
        .map(({ title, text }) => {
          return (
            <TextContainer key={title}>
              <Text weight={TEXT_WEIGHT.bold} margins={[0, 0, 4, 0]}>
                {title}
              </Text>
              <Text>{text}</Text>
            </TextContainer>
          );
        })}
    </Container>
  );
};

import { isOldCert } from './isOldCert';
import { ResponseType } from '../Certificate/types';

export const isPdf = (responseCert: ResponseType) => {
  if (!responseCert.certificateUrl) {
    return !isOldCert(responseCert);
  }

  return responseCert.certificateUrl.toLowerCase().endsWith('.pdf');
};

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Title, TITLE_SIZE } from '../../../componentUI/simple/Title';
import { Button } from '../../../componentUI/simple/Button';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { useIsMobile } from '../../../hooks';
import { useGetCertContext } from '../../hooks/useGetCertContext';
import { InfoAuth } from '../InfoAuth';
import { isOldCert } from '../../../helpers/isOldCert';

export const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 16px 0 16px;

  ${truthfulAdaptive.minWidth.tablet} {
    padding: 0 40px 0 40px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 0 80px 0 80px;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
  }
`;

export const NumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${COLOR_MAP.text.lightGrey};
  margin-bottom: 28px;
  width: 100%;

  padding: 40px 0 28px 0;

  ${truthfulAdaptive.minWidth.tablet} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    padding: 60px 0 40px 0;
    flex-direction: row;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    max-width: 1280px;
    padding: 50px 0;
  }
`;

export const NumberContainerSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  width: 100%;

  ${truthfulAdaptive.minWidth.tablet} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  ${truthfulAdaptive.minWidth.desktop} {
    flex-direction: row;
  }

  ${truthfulAdaptive.minWidth.desktop1440} {
    max-width: 1280px;
  }
`;

export const NumberOrder = () => {
  const isMobile = useIsMobile();

  const { responseCert } = useGetCertContext();
  const navigate = useNavigate();

  return (
    <NumberWrapper>
      {isOldCert(responseCert) ? (
        <NumberContainer>
          <Title size={TITLE_SIZE.h3}>
            ORDER{isMobile ? <br /> : null} {responseCert?.external_id}
          </Title>
          {!isMobile && <Button onClick={() => navigate('/')}>VERIFY ANOTHER CERTIFICATE</Button>}
        </NumberContainer>
      ) : (
        <>
          <NumberContainer>
            <Title size={TITLE_SIZE.h3}>
              ORDER{isMobile ? <br /> : null} {responseCert?.external_id}
            </Title>
            {!isMobile && <Button onClick={() => navigate('/')}>VERIFY ANOTHER CERTIFICATE</Button>}
          </NumberContainer>
          <InfoAuth />
        </>
      )}
    </NumberWrapper>
  );
};

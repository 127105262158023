import React from 'react';

/**
 * Renders a star SVG component.
 * @returns {JSX.Element} SVG element representing a star.
 */
const Star = (): JSX.Element => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9895 1L14.0762 7.25342L20.979 8.26236L15.9842 13.1272L17.163 20L10.9895 16.7534L4.81598 20L5.99474 13.1272L1 8.26236L7.90273 7.25342L10.9895 1Z"
      fill="#CBAD73"
      stroke="#CBAD73"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/**
 * Renders a partially filled star SVG component.
 * @param {Object} props - The component props.
 * @param {number} props.filledWidth - The width of the filled part of the star.
 * @returns {JSX.Element} SVG element representing a partially filled star.
 */
const PartStar = ({ filledWidth }: { filledWidth: number }): JSX.Element => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9895 1L14.0762 7.25342L20.979 8.26236L15.9842 13.1272L17.163 20L10.9895 16.7534L4.81598 20L5.99474 13.1272L1 8.26236L7.90273 7.25342L10.9895 1Z"
      stroke="#CBAD73"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="mask0_1652_1315" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="21">
      <style>
        {`
          #mask0_1652_1315 {
            mask-type: alpha;
          }
        `}
      </style>
      <path
        d="M11.2197 1L14.3065 7.25342L21.2092 8.26236L16.2145 13.1272L17.3932 20L11.2197 16.7534L5.04621 20L6.22497 13.1272L1.23022 8.26236L8.13296 7.25342L11.2197 1Z"
        fill="#CBAD73"
        stroke="#CBAD73"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask0_1652_1315)">
      <rect width={filledWidth} height="22" fill="#CBAD73" />
    </g>
  </svg>
);

/**
 * Renders a 5 stars rating based on the given rating value.
 * @param {string} rating - The rating value as a string.
 * @returns {JSX.Element} JSX element representing the star rating.
 */
export const renderStars = (rating: string): JSX.Element => {
  const fullStars = Math.floor(Number(rating));
  const decimalPart = Number(rating) - fullStars;
  const filledWidth = 22 * decimalPart;

  const emptyStars = 5 - Math.ceil(Number(rating));

  return (
    <>
      {[...Array(fullStars)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Star key={`full-${index}`} />
      ))}
      {decimalPart > 0 && <PartStar filledWidth={filledWidth} />}
      {[...Array(emptyStars)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PartStar filledWidth={0} key={`empty-${index}`} />
      ))}
    </>
  );
};
